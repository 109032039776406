.lxTruck_status {
  text-align: center;
  & > img {
    width: 24px;
    height: 24px;
  }
}

.p-calendar {
  .p-inputtext {
    width: 100%;
  }
  .p-datepicker {
    min-width: 300px !important;
  }
}

.rotate-image {
  margin-top: 10px;
  width: 500px;
  height: 500px;
  transform: rotate(90deg) translateY(-100%);
  transform-origin: top left;
  padding: 10px 10px 10px 10px;
  border: 1px solid #bfbfbf;
  background-color: white;
}

.spin {
  margin-top: 10px;
  width: 500px;
  height: 500px;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: top left;
  padding: 10px 10px 10px 10px;
  border: 1px solid #bfbfbf;
  background-color: white;
}

.spin180 {
  margin-top: 10px;
  width:500px;
  height:500px;
  transform: rotate(90deg) translateY(-100%);
  transform-origin:top left;
  padding: 10px 10px 10px 10px;
  border: 1px solid #BFBFBF;
  background-color: white;
}

.spin360 {
  margin-top: 10px;
  width:500px;
  height:500px;
  transform: rotate(-360deg);
  transform-origin:button right;
  padding: 10px 10px 10px 10px;
  border: 1px solid #BFBFBF;
  background-color: white;
}
