@import '../theme.scss';
.admin-layout{
    width:100%;
    min-height: 100vh;
    box-sizing: border-box;
    margin:0;
    padding:0;
    display: flex;
    flex-direction: row;
    border-color: $primaryColorTheme; 

    .loader{
        position: fixed;
        z-index: 801;
    }
    

    .admin-layout__main{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        position: relative;
        border-color: $primaryColorTheme; 
        .admin-layout__toolbar{
            position: -webkit-sticky; /* Safari */
            position: sticky;
            top: 0px;
            z-index: 810;
            width:100%;
            height: 53px;
            border:1px solid  $primaryColorTheme;
            background-color: $primaryColorTheme;
            display: flex;
            align-items: center;
            justify-content: space-between;
            z-index: 820;
            .p-dropdown{
                margin-left: 5px; 
                min-height: 40px;
                height: 40px;
                min-width: 12.5em;
                position: relative;
                &-label{
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }

        .admin-layout__content{
            width:100%;
            border:1px solid $greyColorTheme;
            flex-grow: 1;
            
        }

        
        .admin-layout__userinf{
            margin-left: 0px;
            margin-right: 15px;
           
            & button {
                margin : 6px;
            }
            // & .btnNotification{
            //     position: absolute;
            //     top: -10px;
            //     right: -10px;
            //     color: red;
            //     font-weight: bold;
            //     background-color: white;
            //     height: 20px;
            //     width: 20px;
            //     padding: 5px;
            //     border-radius: 100%;
            //     text-align: center;
            //     display: flex;
            //     align-content: center;
            //     justify-content: center;
            //     align-items: center;
            // }
        }
    }

    & .lxSearchMenus{
        margin-left: auto;
        margin-right: 5px; 
        & input {
            height: 40px;
            background-image: url('../assets/images/icons/lupa.png');
            background-size:  10%;
            background-position: 2% 50%;
            background-repeat: no-repeat;
            padding-left: 15% !important; 
            max-width: 200px;
            min-width: 200px;
            
        }
    }
    & .lxSelectEmpresaUnidade.p-dropdown{
        max-width: 200px ;
        min-height: 100% ;
        max-height: 100% ;
    }
}