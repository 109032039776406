@import "../../../theme.scss";

.lxInvoiceInvisible {
  & > :first-child {
    display: none;
  }
}

.lxMultSelectDocStatusHeader {
  display: inline-block;
  height: 20px;
  max-width: 200px;
  & div {
    display: flex;
    margin-right: 5px;
  }

  &-color {
    margin-right: 5px;
    min-width: 18px;
    height: 18px;
    border-radius: 10%;
    border: 1px solid black;
    &.lxSolved {
      background-color: $successColor;
    }
    &.lxWithPendings {
      background-color: $failureColor;
    }
  }
}

.lxActionButtons {
  display: flex;
  align-content: center;
  width: 100%;
  justify-content: center;
  & button {
    width: 32px;
    margin-right: 5px;
  }
}

.lxFilter {
  height: 120px;
  opacity: 1;
  &.lxFilterInvisible {
    height: 0px;
    opacity: 0;
  }
}

.lxLineToggleHeader {
  display: flex;
  align-items: center;
  & > .lxSplitter {
    margin: 5px 2px 5px 0px;
    width: 100%;
  }
  & > button {
    width: 21px;
    height: 20px;
    border: 2px solid $dashboardGrey;
    background-color: white;
    & > i {
      color: $primaryColorTheme;
    }
  }
}

.lxCTeTotalizer {
  display: flex;
  flex-direction: row-reverse;
}

.lxExclamationMark {
  &_NFe {
    color: $warningColor;
    vertical-align: -10%;
  }
  &_Quote {
    color: $failureColor;
    vertical-align: -10%;
  }
  &_Complement {
    color: #913494;
    vertical-align: -10%;
  }
}
.lxNumberCteCell {
  display: flex;
  justify-content: flex-start;
  .lxNumberCte {
    display: flex;
    justify-content: flex-end;
    width: 55%;
    margin-right: 5px;
  }
}
.cteCompra {
  color: #cf7e45;
}
.cteCompraBloqueado {
  color: red;
  font-weight: 600;
}
