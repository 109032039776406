
    .lxAlertIcon {
        color : #EFCE4A;
        &::before{
            font-size: 36px;
        }
      }

      .lxButtonDialogHeader{
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          .lxHeaderTitle{
            display: flex;
            align-items: flex-end;
            h3{
                margin-left: 10px;
                font-size: 22px;
            }
          }
          .lxHeaderClose{
              align-self: flex-end;
              .pi{
                  margin-left: 5px;
              }
          }
      }
