@import '../../theme.scss';

.lx-dashboard__card-access{
    border: 1px solid $lightGreyColor;
    border-radius: 3px;
    width: 25%;
    height: 200px;
    padding: 5px;
    & .pi-star{
        color: $dashboardGrey;
        font-size: 25px;
        &.favorite{
            color: $secondaryColorTheme;
        }
    }
    &__title {
        display: flex;
        align-items: center;
        color: $primaryColorTheme;
        font-size: 20px;
        min-height: 50px;
        vertical-align: middle;
    }
    &__info {
        font-size: 14px;
        color: $dashboardGrey;
    }
    & div {
        margin-left: 7px;
        max-height: 30px;
        padding: 5px;
    }

    &:hover{
        border: 1px solid $secondaryColorTheme;
        
        & .pi-star{
            color: $secondaryColorTheme;
        }
        & .lx-dashboard__card-access__title {
            color: $secondaryColorTheme;
        }
    }
}