.route-view-map {
  position: relative;
  .content-lateral-dir {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 85vh;
    width: 550px;
    background: white;
    min-height: 300px;
  }
  .content-routes {
    padding: 5px;
    // width: 400px;
    background: #ffffff;
    max-height: 100%;
    min-height: 106px;
    border-radius: 4px;
    overflow: auto;

    .p-datatable .p-datatable-thead > tr > th {
      padding: 3px;
    }
  }
  .content-routes-filter {
    padding: 5px;
    // width: 400px;
    background: #ffffff;
    max-height: 100%;
    border-radius: 4px;
  }
  .content-orden-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
 
}
.btn-exit-mapa {
  position: absolute;
  right: 27px;
}
