@import '../../theme.scss';

.login-page{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
   
    &__content{
        & > img{
            position: absolute;
            top:0;
            left:0;
            width:100%;
           
        }
    }
    &__content-form{
        position: absolute;
        z-index: 1;
        right : calc(50% - 300px);;
        top: 20%;
        border:1px solid #E4E4E4;
        border-radius: 5px;
        background-color: white;
        width:600px;
        height: 550px;
    }
    &__img-backgroud{
        height: 100%;
        overflow: hidden;
    }
}

